.full-height {
  height: 100%;
  margin: 0;
  overflow: hidden;
}

.root-container {
  height: 100%;
}

.map-container {
  height: 100%;
}

.custom-search-container {
  position: relative;
}

.search-input-container {
  position: relative;
  display: inline-block;
}


.search-icon {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  color: #555;
  font-size: 15px;
}

.poi-panel {
  position: relative;
  z-index: 1;
  /* Set a lower z-index for the panel */
}

.poi-cover-photo-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 0;
  /* Set the lowest z-index for the cover photo container */
}

.image-panel-container {
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 1;
  /* Set a higher z-index to ensure it's above other elements */
}

.image-panel-container .image-gallery {
  width: 100%;
  height: 100%;
}

.image-panel-container .image-gallery .image-gallery-slide img {
  width: 100%;
  height: calc(100vh - 100px);
  object-fit: cover;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
  height: 80px;
  object-fit: cover;
}

.panel-container {
  display: flex;
  height: 100vh;
}

.panel {
  flex: 1;
  position: relative;
  z-index: 1;
}

.poi-cover-photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.search-box-container {
  margin-left: 30px;
  position: relative;
  z-index: 2;
}

.poi-category-buttons {
  border-radius: 20px !important;
  margin-left: 10px !important;
  color: #202124 !important;
  box-shadow: 0 1px 2px rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15) !important;
  display: flex !important;
  align-items: center !important;
}

.poi-category-buttons-text {
  font-family: "Google Sans", Roboto, Arial, sans-serif;
  font-size: 0.85rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.25rem;
}

.photosText {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.photoButton {
  cursor: pointer;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  height: 120px;
  width: 100%;
  padding: 0px;
}

.photoDiv {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.firstImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /* Adjusted width */
  height: 100%;
  /* Adjusted height */
  object-fit: cover;
  /* Ensure the image fills the button */
  border-radius: 8px;
  /* Applied border radius */
}

.textDiv {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  cursor: pointer;
  background-image: linear-gradient(transparent 0%, transparent 20%, rgba(0, 0, 0, 0.15) 40%, rgba(0, 0, 0, 0.4) 60%, rgba(0, 0, 0, 0.6) 80%, rgba(0, 0, 0, 0.7) 100%);
}

.labelDiv {
  line-height: 24px;
  cursor: pointer;
  color: #fff;
  position: absolute;
  text-align: left;
  padding: 4px 8px;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 1;
}

.search-panel-container {
  width: 100%;
}

button.gm-ui-hover-effect {
  visibility: hidden;
}

.gm-style .gm-style-iw-d {
  overflow: hidden !important;
}

.gm-style .gm-style-iw-c {
  padding-left: 0px;
  padding-top: 0px;
}

.tab-container {
  display: flex;
}

.tabs {
  width: 150px;
  /* Adjust width as needed */
  background-color: #f2f2f2;
}

.tab {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
}

.tab.active {
  background-color: #bfdfff;
}

.contributions {
  padding: 10px;
}

.contributions ul {
  list-style-type: none;
  padding: 0;
}

.contributions li {
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
}

.contributions li:last-child {
  border-bottom: none;
}



.dropdown {
  position: relative;
  color: #333;
  cursor: default;
}

.dropdown .arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: " ";
  display: block;
  height: 0;
  margin-top: 0.3rem;
  position: absolute;
  right: 10px;
  top: 14px;
  width: 0;
}

.dropdown .arrow.open {
  border-color: transparent transparent #999;
  border-width: 0 5px 5px;
}

.dropdown .selected-value input {
  line-height: 1.5;
  font-size: 1rem;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  box-sizing: border-box;
  cursor: default;
  outline: none;
  padding: 8px 52px 8px 10px;
  transition: all 200ms ease;
  width: 100%;
}

.dropdown .options {
  display: none;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
}

.dropdown .options.open {
  display: block;
}

.dropdown .option {
  box-sizing: border-box;
  color: rgba(51, 51, 51, 0.8);
  cursor: pointer;
  display: block;
  padding: 8px 10px;
}

.dropdown .option.selected,
.dropdown .option:hover {
  background-color: #f2f9fc;
  color: #333;
}

.nav-tabs .nav-link.active {
  color: #007bff !important;
}

.nav-tabs .nav-link {
  color: #495057 !important;
}

.htmlRendered {
  font-size: 0.85rem !important;
  font-family: "Open Sans", sans-serif !important;
  line-height: 1.1rem !important;
}